import { IProfile } from "./../interfaces/profile.interface";
import { useState, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Buffer } from "buffer";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import getAuthHeader from "../utils/getAuthHeader";
import {
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useDispatch } from "react-redux";
import { setSignLanguageVideoState } from "../store/reducers/sign-language.reducer";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";

declare global {
  interface Window {
    client: any;
  }
}

export default function useSignLanguage(id?: string | null) {
  let uid: string | null = null;

  let profile: any;
  if (!profile || !profile.uid) {
    uid = uuidv4() + "-" + "Video";
  } else {
    uid = profile?.uid + "-666-" + profile.firstName;
  }
  const dispatch = useDispatch();

  const LEAVING_IN_MINUTES = 10;
  const signLanguageState: any = useAppSelector(
    (state: RootState) => state.signLanguage.signLanguageState
  );

  const [localVideoTrack, setLocalVideoTrack] = useState<any>(undefined);

  const [joinState, setJoinState] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [clientInitialized, setClientInitialized] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [leavingIn, setLeavingIn] = useState<any>(undefined);
  const [initLoaded, setInitLoaded] = useState<boolean>(false);

  const [remoteUsers, setRemoteUsers]: any = useState([]);
  const [firebaseData, setFirebaseData]: any = useState({});
  const [remoteUsersNames, setRemoteUserName] = useState<any>([]);
  const [localUser, setLocalUser] = useState<any>();
  const [resourceId, setResourceId] = useState<any>("");
  const [sid, setSid] = useState<any>("");
  const [agoraUid, setAgoraUid] = useState<any>("");
  const [token, setToken] = useState<any>("");

  useEffect(() => {
    if (!id || !initLoaded) {
      return;
    }
    const docRef = doc(db, "sign-language-video", id);
    const unsub = onSnapshot(docRef, (doc: any) => {
      // IN REDUCER SCHREIBEN!!!
      const data = doc.data();
      dispatch(setSignLanguageVideoState(data));
    });
    return () => {
      unsub && unsub();
      setInitLoaded(false);
      dispatch(setSignLanguageVideoState(null));
    };
  }, [id, initLoaded]);

  const firebaseGetData = async (id: string) => {
    const docRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    setFirebaseData(data);
  };
  const firebasePublicGetData = async (id: string) => {
    const docRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    dispatch(setSignLanguageVideoState(data));
    setInitLoaded(true);
  };
  const fetchInitialSignStatus = async (id: string) => {
    const docRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      dispatch(setSignLanguageVideoState(data));
      setInitLoaded(true);
    } else {
      // doc.data() will be undefined in this case
      // Add a new document in collection "cities
      const data: any = {
        createdAt: serverTimestamp(),
        coIntId: null,
        mainId: uid,
        switchVideoHand: false,
        liveStream: false,
        switchVideo: false,
        updatedAt: serverTimestamp(),
      };

      await setDoc(docRef, data);
      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();

      dispatch(setSignLanguageVideoState(docData));
      setInitLoaded(true);
    }
  };

  const apiUrl = `https://api.agora.io/v1/apps/${client?._appId}/cloud_recording`;

  function generateRecordingUID() {
    // Minimum and maximum values for a 32-bit unsigned integer
    const minUID = 100000;
    const maxUID = 999999;

    // Generate a random 6-digit UID
    const recordingUID =
      Math.floor(Math.random() * (maxUID - minUID + 1)) + minUID;

    return recordingUID.toString();
  }

  const recordStream = async () => {
    const agoraRecordingUID = generateRecordingUID();

    const customerKey = "ec204bd189134307865d1d8466f116ef";
    const customerSecret = "8c9922e7747645de808e0c4c748a1097";
    const plainCredential = customerKey + ":" + customerSecret;
    let encodedCredential = Buffer.from(plainCredential).toString("base64");
    let authorizationField = "Basic " + encodedCredential;

    const config = {
      headers: {
        Authorization: authorizationField,
        "Content-Type": "application/json",
      },
    };

    // const test = await axios
    //   .get("https://api.agora.io/dev/v1/projects", configtest)
    //   .then((response) => {
    //     console.log(`Status code: ${response.status}`);
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    if (client) {
      try {
        const response = await axios.post(
          `${apiUrl}/acquire`,
          {
            cname: client?._channelName,
            uid: agoraRecordingUID,
            clientRequest: {
              resourceExpiredHour: 24,
              scene: 0,
            },
          },
          config
        );
        if (response) {
          const start: any = await axios.post(
            `${apiUrl}/resourceid/${response?.data?.resourceId}/mode/individual/start`,
            {
              cname: response.data.cname,
              uid: agoraRecordingUID,
              clientRequest: {
                token: token,
                recordingConfig: {
                  channelType: 0,
                  streamTypes: 1,
                  videoStreamType: 0,
                  // streamMode: "standard", //remove before running or it won't work. Standard mode creates a MPD with WebM, removing steamMode creates M3U8 with TS
                  maxIdleTime: 120,
                  subscribeVideoUids: [`${client?._joinInfo?.uid}`],
                  subscribeUidGroup: 0,
                },
                transcodingConfig: {
                  width: 360,
                  height: 640,
                  fps: 24,
                  bitrate: 800,
                  mixedVideoLayout: 1,
                  maxResolutionUid: "1",
                },
                recordingFileConfig: {
                  avFileType: ["hls"],
                },
                storageConfig: {
                  vendor: 1,
                  region: 21,
                  bucket: "liveaccessbucket",
                  accessKey: "AKIA6GBMEMYMQ53FV74X",
                  secretKey: "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",
                  // fileNamePrefix: ["directory1", "directory2"],
                },
              },
            },
            config
          );
          setResourceId(response?.data?.resourceId);
          setAgoraUid(response?.data?.uid);
          if (start) {
            setSid(start?.data?.sid);
            const query: any = await axios.get(
              `${apiUrl}/resourceid/${start?.data?.resourceId}/sid/${start?.data?.sid}/mode/individual/query`,
              config
            );
          }
        }
        console.log(response.data);
        return response.data;
      } catch (error) {
        console.error("Error beim Anfordern der Ressource:", error);
      }
    }
  };

  const stopRecordingStream = async () => {
    const customerKey = "ec204bd189134307865d1d8466f116ef";
    const customerSecret = "8c9922e7747645de808e0c4c748a1097";
    const plainCredential = customerKey + ":" + customerSecret;
    let encodedCredential = Buffer.from(plainCredential).toString("base64");
    let authorizationField = "Basic " + encodedCredential;

    const config = {
      headers: {
        Authorization: authorizationField,
        "Content-Type": "application/json; charset=utf-8",
      },
    };
    try {
      const stop: any = await axios.post(
        `${apiUrl}/resourceid/${resourceId}/sid/${sid}/mode/individual/stop`,
        {
          cname: client?._channelName,
          uid: agoraUid,
          clientRequest: {},
        },
        config
      );
      console.log("zxcstop", stop);
    } catch (error) {
      console.log("zxcerror", error);
    }
  };
  const initSignFirebase = (id: string) => {
    if (!id) return;

    if (id) {
      fetchInitialSignStatus(id);
      firebasePublicGetData(id);
      dispatch(setSignLanguageVideoState(null));
      setInitLoaded(false);
    }
  };

  const signVideoFirebase = async (id: string | null, uid: string | null) => {
    if (!id) return;
    const signRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(signRef);
    const data: any = docSnap.data();

    if (data.mainId !== uid) {
      await updateDoc(signRef, {
        updatedAt: serverTimestamp(),
        coIntId: uid,
      });
    } else {
      await updateDoc(signRef, {
        updatedAt: serverTimestamp(),
        mainId: uid,
      });
    }
  };
  const clearFirebaseFn = async (id: string) => {
    await deleteDoc(doc(db, "sign-language-video", id));
  };

  const clearFirebase = async (id: string | null, uid: string | null) => {
    if (!id) return;
    const signRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(signRef);
    const data: any = docSnap.data();

    if (data.mainId) {
      await updateDoc(signRef, {
        updatedAt: serverTimestamp(),
        coIntId: uid,
        switchVideoHand: false,
        switchVideo: false,
      });
    } else {
      await updateDoc(signRef, {
        updatedAt: serverTimestamp(),
        mainId: uid,
        switchVideoHand: false,
        switchVideo: false,
      });
    }
  };

  const clearAgora = async () => {
    await handleLeave();
  };

  async function init(_client?: any) {
    if (!_client) {
      _client = AgoraRTC.createClient({ codec: "vp8", mode: "live" });
    }
    AgoraRTC.setLogLevel(4);
    setClient(_client);
    setClientInitialized(true);
  }

  async function createLocalTracks(videoConfig?: any) {
    const videoTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
    setLocalVideoTrack(videoTrack);
    return [videoTrack];
  }

  async function join(channel: string, role: "audience" | "host") {
    if (!client) {
      return;
    }
    if (!channel) {
      return;
    }

    if (!role) {
      return;
    }

    setConnecting(true);
    try {
      setJoinState(false);
      if (!client) return;

      // const token = (await functions.httpsCallable('getAgoraToken', {})({ channelName: channel, uid })).data;

      const config = await getAuthHeader();
      const req = {
        data: {
          channelName: channel,
          uid: uid,
        },
      };

      const res = await axios.post(
        `https://services-api.madarray.solutions/agora/generate-token`,
        // `https://europe-west3-interpreteri.cloudfunctions.net/getAgoraToken`,
        req,
        config
      );
      const resAgoraToken: any = await axios.get(
        `https://agora-token-service.madarray.solutions/rtcToken?channelName=${channel}`
      );
      setToken(resAgoraToken.data.key);
      setLocalUser({ uid: uid, role: role });
      if (role == "host") {
        signVideoFirebase(channel, uid);
      }
      const token = resAgoraToken.data.key;
      client.setClientRole(role, role === "host" ? undefined : { level: 1 });

      console.log(client.uid);
      if (role === "host") {
        const [videoTrack] = await createLocalTracks();
        await client.join(
          "b93de4f7d3ff48689e92b81f2ff0b70a",
          channel,
          token || null,
          uid
        );
        console.log("client", client);

        await client.publish([videoTrack]);
      } else {
        await client.join(
          "b93de4f7d3ff48689e92b81f2ff0b70a",
          channel,
          token || null,
          uid
        );
      }

      window.client = client;

      setJoinState(true);
    } finally {
      setConnecting(false);
    }
  }
  // "007eJxTYFgYwqzyRO6Idde9XK8Dqc//H/oha+ho94Rpld9RIV6PmP8KDEmWximpJmnmKcZpaSYWZhaWqZZGSRaGaUZpaQZJ5gaJQj83pDYEMjJkh7AyMjJAIIgvwWBmmphonGhhlJxoaGhpbmZqlmSWmJpibsTAAADbKSPd"
  // "007eJxTYLhpxRNR4v4skTn9ufH8FvfwxtJnAVn/eBTu8CXEO26o3q7AkGRpnJJqkmaeYpyWZmJhZmGZammUZGGYZpSWZpBkbpCowL4ptSGQkeHGhQpGRgYIBPElGMxMExONEy2MkhMNDS3NzUzNkswSU1PMjRgYAIOAI24="
  async function leave() {
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client?.leave();
  }

  const handleLeave = async () => {
    try {
      setLeavingIn(undefined);
      await leave();

      // close();
    } catch {}
  };

  const getRemoteUsersNames = (rUsers: any[]) => {
    const userUids = rUsers.map((user: any) => user.uid);

    return userUids.map((uid: string) => {
      const pieces = uid.split("-666-");
      const name = pieces[pieces.length - 1];
      const profileUid = pieces[0];
      return { profileUid, name };
    });
  };

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user: any, mediaType: any) => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      console.log("toggle rerender while state of remoteUsers", user);
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (user: any) => {
      console.log("handleUserUnpublished", user);
      exitUser(user.uid);
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserJoined = (user: any) => {
      console.log("user joined", user);
      setRemoteUsers(() => Array.from(client.remoteUsers));
      const names = getRemoteUsersNames(client.remoteUsers);
      console.log("names", names);
      setRemoteUserName(names);
    };
    const handleUserLeft = (user: any) => {
      console.log("handleUserLeft", user);
      exitUser(user.uid);
      setRemoteUsers(() => Array.from(client.remoteUsers));
      const names = getRemoteUsersNames(client.remoteUsers);
      console.log("names", names);
      setRemoteUserName(names);
    };

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  const switchMainVideoRequest = async (
    id: string | null,
    data: any | null
  ) => {
    if (!id) return;
    const signRef = doc(db, "sign-language-video", id);
    firebasePublicGetData(id);

    await updateDoc(signRef, {
      updatedAt: serverTimestamp(),
      ...data,
    });
  };
  // console.log("zxcclientremoteUsers", client?.remoteUsers);
  // console.log("zxcdata", signLanguageState);

  const exitUser = async (uid: string | null) => {
    if (!id) return;
    const signRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(signRef);
    const data: any = docSnap.data();

    if (data.mainId === uid && data.coIntId != null) {
      await updateDoc(signRef, {
        updatedAt: serverTimestamp(),
        coIntId: null,
        liveStream: false,
        switchVideo: true,
      });
    }
    if (
      data.switchVideo == false &&
      client?.remoteUsers.length == 0 &&
      data?.mainId === uid &&
      data?.coIntId === null
    ) {
      deleteFirebase(id);
      stopRecordingStream()
    }
    if (data.coIntId == uid) {
      await updateDoc(signRef, {
        updatedAt: serverTimestamp(),
        coIntId: null,
      });
    }
    stopRecordingStream()
  };
  const switchMainVideo = async () => {
    if (!id) return;
    const signRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(signRef);
    const data: any = docSnap.data();

    firebasePublicGetData(id);
    if (data.switchVideo) {
      if (data.mainId && data.coIntId) {
        await updateDoc(signRef, {
          updatedAt: serverTimestamp(),
          mainId: data.coIntId,
          coIntId: data.mainId,
          switchVideoHand: false,
          switchVideo: false,
        });
      } else {
        await updateDoc(signRef, {
          updatedAt: serverTimestamp(),
          mainId: localUser.uid,
          switchVideoHand: false,
          switchVideo: false,
        });
      }
    }
    // if (data.mainId) {
    //   if (data.switchVideo) {
    //     await updateDoc(signRef, {
    //       updatedAt: serverTimestamp(),
    //       mainId: uid,
    //       switchVideoHand: false,
    //       switchVideo: false,
    //     });
    //   } else {
    //     await updateDoc(signRef, {
    //       updatedAt: serverTimestamp(),
    //       coIntId: uid,
    //       switchVideoHand: false,
    //       switchVideo: false,
    //     });
    //   }
    // } else {
    //   await updateDoc(signRef, {
    //     updatedAt: serverTimestamp(),
    //     mainId: uid,
    //     switchVideoHand: false,
    //     switchVideo: false,
    //   });
    // }
  };

  const deleteFirebase = async (id: string) => {
    await deleteDoc(doc(db, "sign-language-video", id));
  };
  useEffect(() => {
    if (remoteUsers && remoteUsers.length === 0 && joinState) {
      setLeavingIn(LEAVING_IN_MINUTES * 60);
    } else if (remoteUsers && remoteUsers.length > 0) {
      setLeavingIn(undefined);
    }
  }, [remoteUsers, joinState]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleLeave);
    window.addEventListener("unload", handleLeave);
    return () => {
      window.removeEventListener("beforeunload", handleLeave);
      window.removeEventListener("unload", handleLeave);
    };
  });

  /*
  // TODO: make this work
  console.log('LEAVING IN', leavingIn);

  useEffect(() => {
    if (leavingIn !== undefined && leavingIn > 0) {
      setLeavingTimeTimer(
        setTimeout(() => {
          if (
            leavingIn !== undefined &&
            (!remoteUsers || remoteUsers.length === 0)
          ) {
            setLeavingIn(leavingIn - 1);
          } else {
            setLeavingIn(undefined);
          }
        }, 1000)
      );
    } else if (leavingIn <= 0) {
      handleLeave();
    }

    return () => {
      if (leavingTimeTimer) {
        clearTimeout(leavingTimeTimer);
      }
    }
  }, []);

  */

  const acquireRecordingResource = async (cname: string) => {
    try {
      const response = await axios.post(
        "https://services-api.madarray.solutions/agora/acquire",
        {
          cname,
          uid,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error beim Anfordern der Ressource:", error);
    }
  };
  const startLiveStream = async () => {
    if (!id) return;
    const signRef = doc(db, "sign-language-video", id);
    const docSnap = await getDoc(signRef);
    const data: any = docSnap.data();
    await updateDoc(signRef, {
      liveStream: !data.liveStream,
    });
  };

  const stopRecording = async (resourceId: any, sid: any) => {
    const appId = "b93de4f7d3ff48689e92b81f2ff0b70a";
    const customerId = "b1b6de3117d94f87be543274850cb7b9";
    const customerCertificate = "ebd04c1b45ba494e910957087d07d5fe";

    try {
      const response = await axios.post(
        `https://api.agora.io/v1/apps/${appId}/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/mix/stop`,
        {
          clientRequest: {},
        },
        {
          headers: {
            Authorization: `Basic ${Buffer.from(
              `${customerId}:${customerCertificate}`
            ).toString("base64")}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Aufnahme gestoppt", response.data);
      return response.data;
    } catch (error) {
      console.error("Fehler beim Stoppen der Aufnahme", error);
      throw error;
    }
  };

  return {
    join,
    init,
    signVideoFirebase,
    initSignFirebase,
    firebasePublicGetData,
    switchMainVideo,
    switchMainVideoRequest,
    recordStream,
    clearFirebase,
    clearFirebaseFn,
    leave,
    clearAgora,
    firebaseGetData,
    deleteFirebase,
    exitUser,
    stopRecording,
    stopRecordingStream,
    acquireRecordingResource,
    startLiveStream,
    firebaseData,
    signLanguageState,
    localUser,
    remoteUsers,
    remoteUsersNames,
    connecting,
    clientInitialized,
    localVideoTrack,
    joinState,
  };
}
