import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Center,
  Grid,
  Group,
  Loader,
  Switch,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import SignLanguageVideoRoom from "../video-room/SignLanguageVideoRoom";
import useSignLanguage from "../../../hooks/sign-language.hooks";
import Player from "../player";
import SignLanguageAdmin from "../public-event/SignLanguageAdmin";
import { HiOutlineRefresh } from "react-icons/hi";
import { CiStreamOff, CiStreamOn } from "react-icons/ci";
import fixWebmDuration from "webm-duration-fix";
import { useTranslation } from "react-i18next";

let mediaRecorder: any;
let recordedChunks: any = [];

const SignLanguage = ({ event, setDisabledSettingsButtons }: any) => {
  const { classes } = useStyles();
  const [joined, setJoined] = useState(false);
  const [adminJoin, setAdminJoin] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);
  const [recordError, setRecordError] = useState("");
  const [player, setPlayer] = useState(null);
  const [timer, setTimer] = useState(3);
  const [currentDocId, setCurrentDocId]: any = useState<null | string>(null);
  const { t } = useTranslation("common");
  const playerOptions = {};

  const {
    init,
    join,
    leave,
    clearFirebaseFn,
    switchMainVideo,
    recordStream,
    stopRecording,
    clearAgora,
    deleteFirebase,
    initSignFirebase,
    switchMainVideoRequest,
    firebasePublicGetData,
    firebaseGetData,
    firebaseData,
    exitUser,
    localVideoTrack,
    localUser,
    remoteUsers,
    connecting,
    joinState,
    signLanguageState,
    stopRecordingStream,
    startLiveStream,
    clientInitialized,
  } = useSignLanguage(currentDocId);

  useEffect(() => {
    if (recording) {
      setRecordError("");
    }
  }, [recording]);
  useEffect(() => {
    const handleBeforeUnload = () => {
      exitUser(localUser.uid);
      // stopRecordingStream()
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [joinState, remoteUsers, localUser]);

  useEffect(() => {
    if (
      signLanguageState &&
      signLanguageState?.liveStream &&
      signLanguageState?.mainId == localUser?.uid
    ) {
      if (!recording) {
        recordStreamButton();
        setRecording(true);
      }
      // recordStream();
    } else {
      if (recording) {
        stopRecordingButton();
        setRecording(false);
      }
    }
  }, [
    clientInitialized,
    joined,
    connecting,
    signLanguageState,
    localUser,
    recording,
  ]);

  useEffect(() => {
    firebaseGetData(event.id);
  }, []);
  useEffect(() => {
    if (event) {
      init();
      setCurrentDocId(event.id);
    }
    return () => {};
  }, [event]);
  useEffect(() => {
    if (event && signLanguageState == undefined && joinState) {
      window.location.reload();
    }
    return () => {};
  }, [event, joined, signLanguageState, joinState, connecting]);
  const resetEvent = () => {
    setJoined(false);
    clearFirebaseFn(event.id);
    if (adminJoin) {
      setAdminJoin(false);
    }
  };
  const button = () => {
    setJoined(true);
    setAdminJoin(false);
    join(event.id, "host");
    initSignFirebase(event.id);
  };
  const buttonAudience = () => {
    setAdminJoin(!adminJoin);
    setJoined(false);
  };

  useEffect(() => {
    if (localVideoTrack != undefined) {
      if (signLanguageState?.mainId == localUser.uid) {
        localVideoTrack.play("video-container-1");
      } else {
        localVideoTrack.play("video-container-2");
      }
    }
  }, [localVideoTrack, localUser, signLanguageState]);

  useEffect(() => {
    const remoteVideo = remoteUsers[0];

    if (remoteVideo) {
      if (signLanguageState?.mainId == remoteVideo?.uid) {
        remoteVideo?.videoTrack?.play("video-container-1");
      } else {
        remoteVideo?.videoTrack?.play("video-container-2");
      }
    }
  }, [connecting, remoteUsers, signLanguageState]);

  const startSwitchVideo: any = () => {
    const data = {
      switchVideoHand: false,
      switchVideo: true,
    };
    switchMainVideoRequest(event.id, data);
  };

  useEffect(() => {
    if (joined) {
      setDisabledSettingsButtons(true);
    }
  }, [joined]);
  useEffect(() => {
    let timer: any;

    if (signLanguageState != null) {
      if (signLanguageState.switchVideo) {
        timer = setInterval(() => {
          setTimer((prevSayac) => {
            if (prevSayac === 1) {
              clearInterval(timer);
              switchVideo();
            }
            return prevSayac - 1;
          });
        }, 1000);
      }
    }
    return () => {
      clearInterval(timer);
    };
  }, [signLanguageState]);

  const switchVideo = () => {
    switchMainVideo();
    setTimer(3);
  };

  const switchMainRequest = () => {
    const data = {
      switchVideoHand: !signLanguageState?.switchVideoHand,
    };
    switchMainVideoRequest(event.id, data);
  };

  const recordStreamButton = async () => {
    const mimeType = "video/webm;codecs=vp9";
    try {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          mediaRecorder = new MediaRecorder(stream, {
            mimeType: mimeType,
          });

          mediaRecorder.ondataavailable = (event: any) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data);
            }
          };

          mediaRecorder.onstop = async () => {
            setRecordLoading(true);
            setRecordError("");
            let recordedBlob: any = await fixWebmDuration(
              new Blob(recordedChunks, {
                type: mimeType,
              })
            );
            // let formData = new FormData();
            // formData.append("file", recordedBlob, "test.webm");
            // try {
            //   let response = await fetch(
            //     "https://upload-service.madarray.solutions/upload",
            //     {
            //       method: "POST",
            //       body: formData,
            //     }
            //   );

            //   if (response.ok) {
            //     console.log("File uploaded successfully");
            //     setRecordLoading(false);
            //     setRecordError("");
            //   } else {
            //     console.error("File upload failed");
            //     setRecordLoading(false);
            //     setRecordError(t("signLanguage.recordError"));
            //   }
            // } catch (error) {
            //   setRecordLoading(false);
            //   setRecordError(t("signLanguage.recordError"));
            //   console.error("Error uploading file:", error);
            // }
            let videoUrl = URL.createObjectURL(recordedBlob);
            let downloadLink = document.createElement("a");
            downloadLink.href = videoUrl;
            downloadLink.download = `swisstxt_${
              event.shortUid
            }_${new Date()}.webm`;
            //   console.log("zxcdownloadLink",downloadLink)
            document.body.appendChild(downloadLink);
            downloadLink.click();
          };

          mediaRecorder.start();
          console.log("Recording started.");
        })
        .catch((error) => {
          console.error(
            "Kamera erişimi reddedildi veya bir hata oluştu:",
            error
          );
        });
    } catch (err) {
      console.error("An error occurred while loading ffmpeg:", err);
    }
  };

  const stopRecordingButton = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      recordedChunks = [];
      console.log("Recording stopped.");
    }
  };

  const startEventLive = (e: any) => {
    setRecording(e);
    if (e) {
      stopRecordingButton();
    } else {
    }
  };

  return (
    <Card
      p="lg"
      style={{
        position: "relative",
        overflow: "initial",
        marginBottom: 10,
      }}
    >
      {/* {loading && (
        <div className={classes.date_loading}>
          <Loader color="red" variant="dots" />
        </div>
      )} */}
      <Grid>
        <Grid.Col xs={7} lg={8}>
          {joined ? (
            <Group>
              <Text
                color={signLanguageState?.liveStream ? "black" : "gray"}
                size="xl"
              >
                {signLanguageState?.liveStream
                  ? t("signLanguage.streamLive")
                  : t("signLanguage.streamOffline")}{" "}
              </Text>
              {(joined || adminJoin) && (
                <Button
                  color={signLanguageState?.liveStream ? "red" : "green"}
                  onClick={startLiveStream}
                >
                  {signLanguageState?.liveStream ? (
                    <CiStreamOff size={25} />
                  ) : (
                    <CiStreamOn size={25} color="white" />
                  )}
                  <Text ml={10}>
                    {signLanguageState?.liveStream
                      ? t("signLanguage.streamStop")
                      : t("signLanguage.streamLiveUpper")}
                  </Text>
                </Button>
              )}
            </Group>
          ) : (
            <Title>Stream</Title>
          )}
          {firebaseData?.coIntId != undefined && !joined && (
            <Text>{t("signLanguage.roomFull")}</Text>
          )}

          {!joined && (
            <>
              {!adminJoin && (
                <Button
                  disabled={firebaseData?.coIntId != undefined}
                  onClick={button}
                >
                  {t("signLanguage.joinRoom")}
                </Button>
              )}
            </>
          )}

          {joined && !adminJoin && (
            <>
              <SignLanguageVideoRoom
                live={signLanguageState?.liveStream}
                switchMainRequestFunction={switchMainRequest}
                switchVideoRequest={signLanguageState?.switchVideoHand}
                switchVideo={signLanguageState?.switchVideo}
                sideVideoActive={remoteUsers.length > 0}
                switchFunction={startSwitchVideo}
                switchTimer={timer}
              />
            </>
          )}
          {adminJoin && (
            <SignLanguageAdmin event={event} showSignLanguage={true} />
          )}
        </Grid.Col>
        <Grid.Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          xs={5}
          lg={4}
        >
          <div>
            {/* {joined && (
              <Group position="right" mb={10}>
                <Text color={recordError.length > 0 ? "red" : "black"}>
                  {recordLoading
                    ? t("signLanguage.streamUploading")
                    : recordError.length > 0
                    ? recordError
                    : t("signLanguage.streamRecording")}
                </Text>
                <div style={{ position: "relative" }}>
                  {recordLoading && (
                    <div className={classes.record_loading}>
                      <Loader color="red" style={{ height: "100%" }} />
                    </div>
                  )}
                  <Switch
                    styles={{
                      input: {
                        ":checked": {
                          backgroundColor: "#fa5252",
                          border: 0,
                        },
                      },
                    }}
                    disabled={recordLoading}
                    checked={recording}
                    onChange={(e: any) => recordSwitch(e.currentTarget.checked)}
                  />
                </div>
              </Group>
            )} */}
            <div style={{ marginTop: 60 }}>
              {(joined || adminJoin) && (
                <>
                  {/* <Button color="green" fullWidth mb={10} onClick={recordStream}>
                Record Stream
              </Button>
              <Button
                color="red"
                fullWidth
                mb={10}
                onClick={stopRecordingStream}
              >
                Stop Stream
              </Button> */}
                </>
              )}

              {(event.usersRole == "Admin" || event.usersRole == "Owner") && (
                <Button
                  color={adminJoin ? "red" : "green"}
                  onClick={buttonAudience}
                  fullWidth
                >
                  {adminJoin
                    ? t("signLanguage.exitRoomAdmin")
                    : t("signLanguage.joinRoomAdmin")}
                </Button>
              )}
              {event?.showPlayer && (
                <div className={classes.player}>
                  <Player
                    onPlayerDispose={setPlayer}
                    playerOptions={playerOptions}
                    showPlayer={event?.showPlayer}
                    onPlayerInit={setPlayer}
                    sources={event?.sources}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <button
              onClick={resetEvent}
              style={{
                backgroundColor: "transparent",
                width: "auto",
                alignItems: "center",
                display: "flex",
                gap: 5,
                marginTop: 10,
                border: 0,
                color: "gray",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("signLanguage.resetRoom")}{" "}
              <HiOutlineRefresh size={12} color="gray" />
            </button>
          </div>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
const useStyles = createStyles((theme) => ({
  date_loading: {
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.5)",
    width: "100%",
    position: "absolute",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  player: {
    height: "30vh",
    marginTop: 20,
    "@media (max-width: 1200px)": {
      height: "30vh",
    },
  },
  timer: {
    position: "relative",
    height: 100,
    marginBottom: 15,
  },
  timer_bg: {
    position: "absolute",
    width: 100,
    height: 100,
    zIndex: 1,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
    animation: "timerBgAnimation 1s linear infinite",
    "@keyframes timerBgAnimation": {
      "0%": {
        transform: "scale(1)",
        backgroundColor: theme.colors.red[6],
      },
      "50%": {
        transform: "scale(1.1)",
        backgroundColor: theme.colors.red[7],
      },
      "100%": {
        transform: "scale(1)",
        backgroundColor: theme.colors.red[6],
      },
    },
  },
  timer_bg_2: {
    position: "absolute",
    width: 100,
    height: 100,
    borderRadius: "50%",
    zIndex: 0,
    opacity: 0.6,
    animation: "timerBgAnimation2 1s linear infinite",
    "@keyframes timerBgAnimation2": {
      "0%": {
        transform: "scale(1.2)",
        backgroundColor: theme.colors.red[3],
      },
      "50%": {
        transform: "scale(1)",
        backgroundColor: theme.colors.red[2],
      },
      "100%": {
        transform: "scale(1.2)",
        backgroundColor: theme.colors.red[3],
      },
    },
  },
  record_loading: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.3)",
    position: "absolute",
    right: 0,
    top: 0,
    borderRadius: 7,
    zIndex: 9,
  },
}));
export default SignLanguage;
